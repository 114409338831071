import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes, {string} from "prop-types";
import { useToasts } from 'react-toast-notifications'

// Components
import Modal from "../../components/Modals/";
import EditModal from "../../components/Modals/EditModal";
import EditPasswordModal from "../../components/Modals/EditPasswordModal";
import EditAccountModal from "../../components/Modals/EditAccountModal";
import Subscriptions from "../../components/Modals/Subscriptions";
import { logout, profileUpdate, resetCurrentUserState } from '../../actions/auth';

// Actions
import { changePassword, resetpasswordErrorMessage, resetIsPasswordChange } from "../../actions/account";

const Account = ({ logout, history, changePassword, currentUser, profileUpdate, changePasswordErrorMessage, resetpasswordErrorMessage, isPasswordChange, resetIsPasswordChange, resetCurrentUserState }) => {
  // Madal
  const [isOpenMadal, setIsOpenMadal] = useState(false);
  const [typeMadal, setTypeMadal] = useState("");

  const [user, setUser] = useState("")

  useEffect(() => {
    const userInfo =  JSON.parse(localStorage.getItem("currentUser"))
    resetCurrentUserState(userInfo)
  }, [])

  useEffect(() => {
    const userInfo =  JSON.parse(localStorage.getItem("currentUser"))
    setUser(userInfo)
  }, [currentUser])
 
  const escFunction = (event) => {
    if(event.keyCode === 27) {
      setIsOpenMadal(false)
    }
  };

  const { addToast } = useToasts()

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  
  const hendleSubmitUpdateForm = ({firstname, lastname }) => {
    profileUpdate({firstname, lastname, history})
    addToast('Name changed Successfully', {
      appearance: 'success',
    })
  };

  const hendleSubmitUpdatePassword = async ({oldPassword, newPassword, repeatPassword }) => {
    changePassword({oldPassword, newPassword, repeatPassword});
  };

  useEffect(() => {
    if(isPasswordChange == true){
      resetIsPasswordChange()
      setIsOpenMadal(false)
      addToast('Password changed Successfully', {
        appearance: 'success',
      })
    }
  }, [isPasswordChange])

  return (
    <Fragment>
      {/* Header */}
      <div className="header">
        <div className="header_logo" onClick={() => history.push("/")}>
          <div className="header_logo_images">
            <img src="/images/logo.svg" alt="" />
          </div>
          <div className="header_logo_text">Link shortener</div>
        </div>
        <div className="header_btnWrap">
          <button
            className="btn link"
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            My Links
          </button>
        </div>
      </div>
      {/* Header end */}

      <div className="account">
        <div className="account_table">
          <div className="account_header">Account Management</div>
          <div className="account_body">
            <div className="account_row">
              <div className="account_label">Email:</div>
              <div className="account_text">{user.email}</div>
            </div>
              <div className="account_row">
              <div className="account_label">Name:</div>
              <div className="account_text">
                {user.firstname + " " + user.lastname}
              </div>
              <div
                className="account_button"
                onClick={() => {
                  setTypeMadal("EditAccountModal");
                  setIsOpenMadal(true);
                }}
              >
                Change
              </div>
            </div>
            <div className="account_row">
              <div className="account_label">Password:</div>
              <div className="account_text">*************</div>
              <div
                className="account_button"
                onClick={() => {
                  setTypeMadal("EditPasswordModal");
                  setIsOpenMadal(true);
                }}
              >
                Change
              </div>
            </div>
          </div>
        </div>
        <div className="simplifyYourLinks">
          <img
            className="simplifyYourLinks-images"
            src="/images/logo.svg"
            alt="logo"
          />
          <div className="simplifyYourLinks-text">simplify your links</div>
        </div>
      </div>

      {/* Modals */}
      <Modal
        open={isOpenMadal}
        close={() => setIsOpenMadal(false)}
        customClass={typeMadal}
      >
        {typeMadal === "EditAccountModal" && (
          <EditAccountModal
            close={() => setIsOpenMadal(false)}
            handleSubmit={hendleSubmitUpdateForm}
            firstname={user.firstname}
            lastname={user.lastname}
          />
        )}
        {typeMadal === "EditPasswordModal" && (
          <EditPasswordModal
            close={() => setIsOpenMadal(false)}
            handleSubmit={hendleSubmitUpdatePassword}
            passwordErrorMessage={changePasswordErrorMessage}
            resetpasswordErrorMessage={resetpasswordErrorMessage}
          />
        )}
        {typeMadal === "SubscriptionsModal" && <Subscriptions />}
      </Modal>
      {/* Modals end */}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  isPasswordChange: state.auth.isPasswordChange,
  changePasswordErrorMessage: state.auth.changePasswordErrorMessage

  
});

Account.propTypes = {
  logout:PropTypes.func,
  currentUser:PropTypes.object,
  profileUpdate: PropTypes.func,
  isPasswordChange: PropTypes.bool,
  changePasswordErrorMessage: string,
  resetpasswordErrorMessage: PropTypes.any,
  isPasswordChange: PropTypes.any,
  resetIsPasswordChange: PropTypes.func,
  resetCurrentUserState: PropTypes.func,


};

const mapDispatchToProps = {
  logout,
  changePassword,
  profileUpdate,
  resetpasswordErrorMessage,
  resetIsPasswordChange,
  resetCurrentUserState
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
