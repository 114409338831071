import React, { Fragment, useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSubscriptions, showUpdatedItemOnTop } from "../../actions/dashboard";
import API from '../../helpers/API'

const Subscriptions = ({history, getSubscriptions, subscriptions, keyword, existingKeyword, highlightRow, showUpdatedItemOnTop}) => {

  useEffect(() => {
    getSubscriptions()
  }, [])
  const subscriptionUrl = async (elem, kw) => {
    if(existingKeyword) {
      kw = existingKeyword.id ? existingKeyword : kw;
    }
    let currentUser = localStorage.getItem("currentUser");
    currentUser = JSON.parse(currentUser)
    if (currentUser.is_admin === 1) {
      let data = {
        subscription_id: elem.id,
        keyword_id: kw.id,
        user_id: currentUser.id
      }
      const kw_payment = await API.adminPaymentActive(data);
      const kw_id = kw_payment.data.keyword_id;
      await showUpdatedItemOnTop({id: kw_id});
      highlightRow(kw_id);
      return
    }
    return (window.location.href = `${elem.payment_url}&passthrough[keyword]=${kw.id}`)
  }

  return (
    <Fragment>
      <div className="modalTable_header">
        Select one of the following subscriptions to reserve 123.boxonline.com/{keyword === undefined ? "" : keyword.keyword}
      </div>
      <div className="SubItems">
        {subscriptions.map(elem => {
          return (
            <div className="SubItem" key={elem.id}>
              <div className="SubItem_header">{elem.name}</div>
              <div className="SubItem_content">
                <div className="SubItem_title">{elem.amount}{elem.currency}</div>
                {elem.per_month && <div className="SubItem_description">{elem.per_month} {elem.currency} / mo</div>}
                {elem.discount && <div className="SubItem_save">Save Over {elem.discount}</div>}
                <button onClick={async () => {subscriptionUrl(elem, keyword)}} className="btn default grey">Order Now</button>
              </div>
              <p className="SubItem_detail">{elem.description}</p>
            </div>
          )
        })}
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    subscriptions: state.dashboard.subscriptions,
    table: state.table.table
  }
};

const mapDispatchToProps = {
  getSubscriptions,
  showUpdatedItemOnTop
}

Subscriptions.propTypes = {
  getSubscriptions: PropTypes.func,
  subscriptions: PropTypes.array,
  keyword: PropTypes.object,
  highlightRow: PropTypes.func,
  showUpdatedItemOnTop: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
